import React, { useState } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import logo from './images/logo.png'

import petImage1 from './images/BlueCat.webp'
import petImage2 from './images/GreenDog.webp'
import petImage3 from './images/RedKitten.webp'
import petImage4 from './images/YellowDog.webp'

import iconImage1 from './images/Adopt.webp'
import iconImage2 from './images/Manage.webp'
import iconImage3 from './images/Shop.webp'

import comecat from './images/comecat.png'
import PetsIcon from '@material-ui/icons/Pets';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import SimpleImageSlider from "react-simple-image-slider";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';



const Home = () => {
  const [email, setEmail] = useState('');
  const [alertText, setAlertText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [process, setProcess] = useState(false);

  const handleRegister = () => {
    // setProgress(true);
    // console.log(progress);

    console.log(email);
    

    //setEmail("");
    var em, ph;
    setEmail(email.toLowerCase());
    
    if (email.includes("@")) {
      em = email;
      ph = "";
      
      var validRegex = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)$/;
      
      var validRegex2 = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

      if (em.match(validRegex) || em.match(validRegex2)) {
        setProcess(true);
        setAlertText("");
        fetch('https://lottle-api.herokuapp.com/registeremail', {
          method: 'post',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            email: em,
            mobile: ph,
          })
        }).then(response => response.json())
          .then(user => {
            // console.log(progress);
            setProcess(false);
            console.log(user);
            setEmail("");
            setSuccessText(user.toUpperCase());
            // setSuccessText("REGISTERED SUCCESSFULLY");
          })
      }
      else {
        setAlertText("PLEASE ENTER A VALID EMAIL ADDRESS / MOBILE NUMBER");
        setSuccessText("");
      }

    }
    else {
      ph = email;
      em = "";
      if(ph.indexOf("+91")===0) ph = ph.substring(3) ;
      if (ph.length == 10) {
        setProcess(true);
        setAlertText("");
        fetch('https://lottle-api.herokuapp.com/registeremail', {
          method: 'post',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            email: em,
            mobile: ph,
          })
        }).then(response => response.json())
          .then(user => {
            setProcess(false);
            console.log(user);
            setEmail("");
            setSuccessText(user.toUpperCase());
            // setSuccessText("REGISTERED SUCCESSFULLY");
          })
      }
      else {
        setAlertText("PLEASE ENTER A VALID EMAIL ADDRESS / MOBILE NUMBER");
      }

    }

    console.log(em);
    console.log(ph);
  }



  const images = [
    { url: `${petImage1}` },
    { url: `${petImage2}` },
    { url: `${petImage3}` },
    { url: `${petImage4}` },
  ];



  return (
    <div>
      <Container>
        <div className="top">
          <div className="mobile-navbar only-mobile">
            <img src={logo} alt="" />
          </div>
          <div className="images-box-mobile only-mobile">
            <SimpleImageSlider width={`100%`} height={`100%`} images={images} showBullets={false} showNavs={true} autoPlay={true} autoPlayDelay={3.0} navSize={0} slideDuration={1.0} />
          </div>
          <div className="main">
            <Fade bottom>
              <div className="navbar">
                <img src={logo} alt="" className="logo " />

                <a className="text-link " href="mailto:contact@lottle.in" target="_blank">Contact us</a>
              </div>
            </Fade>
            <Fade bottom>
              <h2 className="title">Get Early Access</h2>
              <h4 className="title-support">Be the first to know when we launch.</h4>
            </Fade>



            <Fade bottom>
              <div className="location-box">
                <input type="text" className="input no-mobile" placeholder='Email Address or Mobile Number' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="text" className="input only-mobile" placeholder='Email or Mobile Number' value={email} onChange={(e) => setEmail(e.target.value)} />
                {
                  process ? (
                    <div className="go-btn-process">
                      <img src="https://i.im.ge/2022/07/23/FamkmX.gif" alt="" />
                    </div>
                  ) : (
                    <div className="go-btn" onClick={handleRegister}> register
                      <PetsIcon className="icon" />
                    </div>
                  )
                }
              </div>
            </Fade>

            <div className="alert-message">
              {
                alertText.length > 0 ? (
                  <CancelIcon className='icon'/>
                ) : (
                  <></>
                )
              }
              {alertText}
            </div>
            <div className="success-message">
              {
                successText.length > 0 ? (
                  <CheckCircleIcon className='icon'/>
                ) : (
                  <></>
                )
              }
              {successText}
            </div>


              <div className="below">
                <Fade bottom>
                  <div className="below-title">LAUNCHING SOON IN KOLKATA</div>
                </Fade>
                <Fade bottom>
                  <div className="below-cities">Stay Connected</div>
                </Fade>
                <Fade bottom>
                  <div className="below-links">
                    <a href="https://www.facebook.com/LottleApp/" target="_blank" className="link">
                      <FacebookIcon className='link-icon' />
                    </a>
                    <a href="https://www.instagram.com/LottleApp/" target="_blank" className="link">
                      <InstagramIcon className='link-icon' />
                    </a>
                    <a href="https://www.youtube.com/channel/UCLwe_nJyjnO-wuVP0ENCn_w" target="_blank" className="link">
                      <YouTubeIcon className='link-icon' />
                    </a>
                  </div>
                </Fade>
              </div>
          </div>


          {/* code */}
          <div className="images-box">
            <SimpleImageSlider width={`100%`} height={`100%`} images={images} showBullets={false} showNavs={true} autoPlay={true} autoPlayDelay={3.0} navSize={0} slideDuration={1.0} />
          </div>

          

        </div>

        <div className="ourspeciality">
          <Fade>
            <div className="item">
              <img src={iconImage1} alt="" />
              <p className="title">adopt</p>
              <p className="desc">verified pets privately <br /> with no fees</p>
            </div>
          </Fade>
          <Fade>
            <div className="item">
              <img src={iconImage2} alt="" />
              <p className="title">manage</p>
              <p className="desc">your pet's health and schedules smartly with Al powered tools</p>
            </div>
          </Fade>
          <Fade>
            <div className="item">
              <img src={iconImage3} alt="" />
              <p className="title">shop</p>
              <p className="desc">for all your pet needs products, supplies and medicines</p>
            </div>
          </Fade>
        </div>

        <div className="follow-us">
          <div className="tilte">stay connected</div>
          <div className="links">
              <a href="https://www.facebook.com/LottleApp/" className="link">
                <FacebookIcon className='link-icon' />
              </a>
              <a href="https://www.instagram.com/LottleApp/" target="_blank" className="link">
                <InstagramIcon className='link-icon' />
              </a>
              <a href="https://www.youtube.com/channel/UCLwe_nJyjnO-wuVP0ENCn_w" target="_blank" className="link">
                <YouTubeIcon className='link-icon' />
              </a>
              <a href="mailto:contact@lottle.in" target="_blank" className="link">
                <MailOutlineIcon className='link-icon' />
              </a>
            </div>
        </div>
      </Container>
    </div>
  )
}

export default Home

const Container = styled.div`
      min-height: 100vh;
      width: 100vw;

      .only-mobile{
          display: none;

          @media(max-width: 480px){
              display: block;
          }
      }

      .no-mobile{
          display: block;

          @media(max-width: 480px){
              display: none;
              height: 0;
              width: 0;
              overflow: 0;
          }
      }

      .top{
        height: 540px;
        width: 100vw;
        
        display: flex;
        justify-content: space-between;
        position: relative;

        @media(max-width: 480px){
            height: 100vh;
            min-height: 600px;
            padding-top: 10vh;
            /* Code-edit */
            flex-direction: column;
            flex-wrap: wrap-reverse;
            overflow: scroll;
        }
        
        @media screen and (max-height: 370px),
          screen and (max-width: 480px){
          padding-top: 66px;
        }


        .mobile-navbar{
          height: 10vh;
          min-height: 66px;
          width: 100vw;
          position: fixed; 
          top: 0;
          background-color: white;
          display: grid;
          place-items: center;
          padding: 1.25vh 0;
          z-index: 10;
          border-bottom: 1px solid #f6ebeb;


          img{
            height: 7.5vh;
            min-height: 49px;
          }

          @media(min-width: 481px){
            display: none;
          }
        }

        .mobile-navbar::before {
          content: ""; 
          position:absolute; 
          z-index: -1; 
          width: 100%;  
          bottom: 0px; 
          height: 2px; 
          background-color: #f954630a;
          box-shadow: 0 5px 14px rgb(0 0 0 / 60%); 
        }

        .main{
          width: calc(100vw - 540px);
          max-width: 1150px;
          /* background-color: pink; */
          height: 540px;
          padding: 40px 5vw;
          
          @media(max-width: 768px){
            width: 100vw;
          }

          @media(max-width: 480px){
            position: relative;
            width: 100vw;
            height: 37.5vh;
            min-height: 225px;
            padding: 10px 5vw;
          }

          .navbar{
            height: 57.5px;
            width: 100%;
            /* background-color: orange; */
            /* border-radius: 10px; */

            display: flex;
            justify-content: space-between;
            overflow: hidden;
            align-items: center;

            .logo{
              height: 100%;
              /* border-radius: 10px; */
            }

            .text-link{
              text-transform: uppercase;
              letter-spacing: 0.1rem;
              font-size: 0.75rem;
              background-color: #f7f0f0; 
              /* color: white; */
              /* border: 1px solid black; */
              font-weight: 500;
              padding: 5px 10px;
              border-radius: 3px;
              text-decoration: none;
            }

            @media(max-width: 480px){
              display: none;
            }
          }


          .title{
            margin-top: 60px;
            font-size: 2.2rem;
            font-weight: 600;

            @media(max-width: 480px){
              font-size: 1.75rem;
              margin-top: 0;
              text-align: center;
            }
          }
          
          .title-support{
            font-size: 0.9rem;
            font-weight: 200;
            margin: 0 0 30px 0;
            color: grey;

            @media(max-width: 480px){
              font-size: 0.8rem;
              text-align: center;
            }
          }

          .location-box{
            margin: 10px 0;
            height: 65px;
            width: 90%;
            border-radius: 100px;
            border: 1px solid #ded9da;
            overflow: hidden;

            display: flex;
            justify-content: space-between;
            align-items: center;

            /* padding-left: 5%; */

            .input{
              height: 100%;
              width: 100%;
              border: transparent;
              /* border: 1px solid black; */
              outline: none;
              letter-spacing: 0.05rem;
              font-weight: 500;
              padding: 0 12.5% 0 5%;
              margin-right: -12.5%;
              border-radius: 100px;

              &:focus{
                background-color: #f7f0f0;
                transition-duration: 250ms;
              }

              &:focus::placeholder{
                color: transparent;
              }

              &::placeholder{
                  transition: color 350ms ease;
                  /* color: transparent; */
              }
            }


            .go-btn{
                height: 100%;
                width: 40%;
                background-color: #fd4557;
                /* background: linear-gradient(110deg, #e2717b 60%, #fd4557 60%); */
                background-color: #f95463;
                border-radius: 100px;
                box-shadow: rgb(54 54 54 / 6%) 0px 1px 2px;
                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: 500;
                font-size: 0.75rem;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
                opacity: 0.9;
                padding: 0 10px;

                .icon{
                  margin-top: -6px;
                  fill: white;
                  margin-left: 5px;
                  font-size: 2rem;
                }

                img{
                  height: 80%;
                }

                &:hover{
                  opacity: 1;
                  transition-duration: 500ms;
                }
            }

            .go-btn-process{
                height: 100%;
                width: 40%;
                background-color: #f95463;
                border-radius: 100px;
                box-shadow: rgb(54 54 54 / 6%) 0px 1px 2px;
                cursor: not-allowed;

                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0.9;
                padding: 0 10px;

                img{
                  height: 100%;
                }
              }


              @media(max-width: 768px){
                width: 100%;
              }

              @media(max-width: 480px){
                margin: 7.5px 0;
                height: 55px;
                padding-left: 3.5%;

                .input{
                  height: 70%;
                  width: 65%;
                  /* border: 1px solid black; */
                  font-size: 0.65rem;
                  letter-spacing: 0.1rem;
                  font-weight: 600;
                  color: #918f8a;
                  padding: 0 5% 0 5%;

                  &:focus{
                    background-color: transparent;
                    transition-duration: 250ms;
                  }
                }


                .go-btn{
                    width: 35%;
                    font-weight: 500;
                    font-size: 0.65rem;
                    letter-spacing: 0.07rem;
                    padding: 0 7.5px;

                    .icon{
                      margin-top: -3.5px;
                      fill: white;
                      margin-left: 5px;
                      font-size: 1.25rem;
                    }
                }

                .go-btn-process{
                  width: 35%;
                }
              }
          }

          .alert-message{
            font-size: 0.67rem;
            margin-left: 5%;
            font-weight: 500;
            letter-spacing: 0.07rem;
            color: #d26b6b;
            display: flex;
            align-items: center;

            .icon{
              font-size: 1.5rem;
              margin-right: 5px;
              fill: #d26b6b;
            }

            @media(max-width: 480px){
                font-size: 0.55rem;
                
                .icon{
                  font-size: 1.15rem;
                }
            }
          }

          .success-message{
            font-size: 0.67rem;
            margin-left: 5%;
            font-weight: 600;
            letter-spacing: 0.1rem;
            color: #1bbc1b;
            display: flex;
            align-items: center;

            .icon{
              font-size: 1.5rem;
              margin-right: 5px;
              fill: #1bbc1b;
            }

            @media(max-width: 480px){
                font-size: 0.55rem;
                
                .icon{
                  font-size: 1.15rem;
                }
            }
          }

          .below{
            position: absolute;
            bottom: 45px;

            .below-title{
              color: grey;
              font-size: 0.95rem;
              letter-spacing: 0.1rem;
              font-weight: 500;
            }
  
            .below-cities{
              font-size: 0.85rem;
              font-weight: 200;
            }

            .below-links{
              width: 85px;
              margin-top: 10px;

              display: flex;
              align-items: center;
              justify-content: space-between;


              .link-icon{
                fill: #a2a2a2;

                &:hover{
                  fill: #f35663;
                  transition-duration: 250ms;
                }
              }
            }


            @media(max-width: 480px){
                position: relative;
                bottom: 0;

                .below-title{
                    font-size: 0.75rem;
                    width: 100%;
                    text-align: center;
                    margin-top: 45px;
                }
        
                .below-cities{
                  display: none;
                  font-size: 0.7rem;
                  font-weight: 200;
                }
                
                .below-links{
                  display: none;
                }
            }
          }
        }
        

        .images-box{
          position: relative;
          width: 600px;
          height: 540px;
          background-color: #e1e2e3;
          overflow: hidden;

          .darken{
            position: absolute;
            width: 600px;
            height: 540px;
            z-index: 2;
          }

          @media(max-width: 768px){
            display: none;
          }
        }

        .images-box-mobile{
          position: relative;
          width: 100vw;
          height: 47.5vh;
          background-color: #e1e2e3;
          overflow: hidden;
          min-height: 300px;
        }
      }


      .ourspeciality{
        height: calc(100vh - 540px);
        min-height: 250px;
        width: 100vw;
        padding: 10px 0;
        /* background-color: #1e1c1c; */
        background-color: #f5eeee;
        position: relative;
        border-top: 1px solid #e4dddd;
        /* box-shadow: 0px 2px 42px -10px rgba(0,0,0,0.75); */

        -webkit-box-shadow: -8px -1px 54px -22px #0000006b;
        -moz-box-shadow: -8px -1px 54px -22px #0000006b;
        box-shadow: -8px -1px 54px -22px #0000006b;

        display: flex;
        justify-content: space-around;
        /* align-items: center; */

        .item{
            height: 100%;
            width: 25%;
            /* background-color: #222; */
            border-radius: 10px;
            padding: 0 2.5%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img{
              height: 50%;
              margin-bottom: 10px;
            }


            p{
              /* color: #cabdbd; */
              text-align: center;
            }

            .title{
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              margin: 2.5px 0 2.5px 0;
              color: #808080;
            }

            .desc{
              font-size: 0.8rem;
              /* font-weight: 200; */
              letter-spacing: 0.07rem;
              color: #a99f9f;
            }
        }

        .footer{
          position: absolute;
          bottom: 0;
          width: 100vw;
          /* background-color: #d9d0d0; */
          text-align: center;
          font-size: 0.7rem;
          font-weight: 200;
          letter-spacing: 0.07rem;
          padding: 12px 0;
          color: black;
        }

        @media(max-width: 480px){
          height: auto;
          padding: 20px 10vw;

          flex-direction: column;
          justify-content: space-around;
          /* align-items: center; */

          box-shadow: none;

          .item{
            height: 250px;
            width: 100%;
            /* background-color: #222; */
            border-radius: 10px;
            padding: 0 2.5%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img{
              height: 50%;
              margin-bottom: 10px;
            }


            p{
              /* color: #cabdbd; */
              text-align: center;
            }

            .title{
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 0.1rem;
              margin: 2.5px 0 2.5px 0;
              color: #808080;
            }

            .desc{
              font-size: 0.8rem;
              /* font-weight: 200; */
              letter-spacing: 0.07rem;
              color: #a99f9f;
            }
          }
        }
      }

      .follow-us{
        border-top: 1px solid #f4e7e3;
        padding: 5vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f3e9e9;

        .tilte{
          font-size: 0.9rem;
          text-transform: uppercase;
          letter-spacing: 0.25rem;
          font-weight: 600;
          color: #808080;
          margin-bottom: 10px;
        }

        .links{
          display: flex;
          width: 30vw;
          justify-content: space-between;
        }

        .link-icon{
          fill: #d48e90;
        }

        @media(min-width: 481px){
          display: none;
        }
      }
  `


const DownloadApp = styled.div`
    height: 600px;
    background-color: #fefdff;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .text{
      width: 50%;
      padding: 0 10%;

      h1{
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 2.8rem;
        margin-bottom: 1rem;
      }

      p{
        font-size: 1rem;
        font-weight: 300;
        color: grey;
      }
      
      .links{
        width: 100%;
        display: flex;
        margin-top: 40px;
    
        img{
          height: 50px;
          margin-right: 10px;
        }
      }
    }

    .images{
      position: relative;
      width: 50%;
      height: 100%;
      padding: 0 5%;

      .image-top{
        height: 500px;
      }

      .image-bottom{
        height: 500px;
        position: absolute;
        bottom: 0;
        right: 5%;
      }
    }
  `


const Footer = styled.div`
    background: #f8f8f8;
    padding: 30px 10%;
    /* border-top: 1px solid #1e1c1c; */
    /* height: 400px; */
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 25%);

    .flex-it{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .top-flex{
        .logo-footer{
          font-size: 2.5rem;
          font-weight: 600;
        }

        .lang-box{
          padding: 6px 14px;
          border-radius: 4px;
          font-size: 0.9rem;
          font-weight: 200;
          border: 1px solid #d5d3d3;
          cursor: pointer;

          &:hover{
            border: 1px solid grey;
            transition-duration: 250ms;
          }

          .icon{
            font-size: 1.2rem;
            margin-bottom: -5px;
            margin-right: 5px;
            fill: grey;
          }
        }
    }



    .middle-flex{
        margin: 30px 0;
        align-items: flex-start;

        .left-middle-flex{
            align-items: flex-start;
            flex: 1;
            max-width: 600px;

            .item{
                /* border: 1px solid black; */

                .head{
                  font-size: 1rem;
                  font-weight: 500;
                  text-transform: uppercase;
                  letter-spacing: 0.1rem;
                  margin: 1rem 0 0.2rem 0;
                }

                .sub-links{
                  text-decoration: none;
                  display: block;
                  font-size: 0.8rem;
                  letter-spacing: 0.07rem;
                  line-height: 1.75rem;
                  color: grey;

                  &:hover{
                    color: #403d3d;
                    transition-duration: 250ms;
                  }
                }
            }
        }

        .right-middle-flex{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;

            .head{
                font-size: 1rem;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
                margin: 1rem 0 0.2rem 0;
            }

            .links{
              display: flex;
              align-items: center;
              margin: 10px 0;
              height: 30px;
              justify-content: space-between;
              /* min-width: 160px; */
              
              a{
                margin-left: 5px;
              }

              img{
                height: 25px;
                width: 25px;
                border-radius: 30px;

                /* &:hover{
                  height: 30px;
                  width: 30px;
                  transition-duration: 250ms;
                } */
              }
            }

            .app-download-links{
              margin-top: 20px;

              a{
                  margin-top: 5px;
                  display: block;

                  img{
                    width: 135px;
                    border-radius: 4px;
                  }
              }
            }
        }
    }


    .bottom-txt{
        border-top: 1px solid #ded5d5;
        padding-top: 20px;
        p{
            font-size: 0.75rem;
            font-weight: 200;
        }
    }
  `