import React, { useState, useEffect } from 'react'

import {
  Routes,
  Route,
} from "react-router-dom";
import styled from 'styled-components'

import Home from './components/Home'
import PrivacyPolicies from './components/PrivacyPolicies';
import Terms from './components/Terms';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/privacy-policies" element={<PrivacyPolicies/>} />
      <Route path="/terms-of-service" element={<Terms/>} />
      
    </Routes>
  )
}

export default App